/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import FoodJPG from '@assets/images/gesundes-essen.jpg';
import NeuroRoomJPG from '@assets/images/neuro-room.jpg';
import NeuroRoomMobileJPG from '@assets/images/neuro-room-mobile.jpg';
import { ContainerSmall } from '~/utils/styles/utils';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';
import { useTranslationContext } from '~/pages/ernährungsberatung';
// import { useTranslationContext as useTranslationContextEn } from '~/pages/en/traumatic-brain-injury';
import { ImageContainer, FlexContainer, List } from '~/utils/styles/utils';
import Image from '~/components/atoms/Image/Image';

const NutritionPage: React.FC = () => {
  const t = useTranslationContext();
  // const tEn = useTranslationContextEn();

  return (
    <>
      <HeaderFooterLayout
        desktopHero={NeuroRoomJPG}
        mobileHero={NeuroRoomMobileJPG}
        altHero="Ernährungsberatung im Zimmer Sportraum Arabellapark."
      >
        <ContainerSmall>
          <FlexContainer>
            <List>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('NutritionContent1'), // || tEn('NutritionContent1'),
                }}
              />
            </List>
            <ImageContainer>
              <Image src={FoodJPG} alt="Dr. med. Uez" width={350} />
            </ImageContainer>
          </FlexContainer>
        </ContainerSmall>
      </HeaderFooterLayout>
    </>
  );
};

export default NutritionPage;
